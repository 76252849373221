<template>
	<b-navbar id="main-nav" toggleable="lg">
		<b-navbar-brand @click="$router.push({name: 'home'})">
			<img id="blackfon-portalcautivo-logo" src="@/assets/Exports/Blackfon-White over black - nonsquare.png"
				alt="Blackfon" />
		</b-navbar-brand>

		<MainNavAccount @click="displayMenu" />

		<!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

		<b-collapse id="nav-collapse" is-nav>
			<b-navbar-nav class="ml-auto">
				<b-nav-item href="/">
					<i class="material-symbols-outlined">home</i>
				</b-nav-item>
			</b-navbar-nav>
		</b-collapse> -->

		<ui-modal id="mainNavModal" v-model="menu" hide-footer>
			<div slot="header">Mi cuenta</div>
			<div class="text-center">
				<div class="img-holder">
					<img :src="accountGetUsuario.imagenes_id_avatar | img">
				</div>
			</div>
			<div class="p-2">
				<div class="textual text-center">
					<div class="nombre">
						{{ accountGetUsuario.nombre }}
					</div>
					<div class="email">
						{{ accountGetUsuario.email }}
					</div>
				</div>
			</div>
			<div class="list-like text-danger" @click="logOut">
				Cerrar sesión
			</div>
		</ui-modal>

	</b-navbar>
</template>

<script>
import Vue from "vue";
import langSwitcher from './lang-switcher.vue'
import MainNavAccount from "./MainNavAccount.vue";
import uiModal from "./ui-modal.vue";
import { mapGetters } from "vuex";
import { tryToLogout } from "@/dexie/sesion";


export default Vue.extend({
	name: "BlackfonMxNav",
	components: { langSwitcher, MainNavAccount, uiModal },
	data: () => {
		return {
			menu: false
		}
	},
	computed: {
		...mapGetters(['isLogged', 'accountGetUsuario'])
	},
	methods: {
		async logOut(){
			if(tryToLogout())
				this.menu = false;
		},
		displayMenu() {
			if (this.isLogged)
				this.menu = true;
		}
	}
});
</script>

<style lang="scss">
@import '/src/scss/app.scss';
$imgSize: 10em;

#main-nav {
	text-transform: uppercase;
	position: relative;
	z-index: 100;
	border-bottom: 2px solid white;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-around;

	a {
		color: white;
	}

	.textual {
		flex: 1 0 auto;
		text-align: right;

		.nombre {
			// font-size: 10px;
			color: $accent;
		}

		.email {
			// font-size: 8px;
			font-size: 0.8em;
			color: $accent-dimmed;
		}
	}

	.img-holder {
		flex: 0 0 $imgSize;

		img {
			max-height: $imgSize;
			max-width: $imgSize;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
	}

	.modal-vue-frame {
		max-width: 426px;
	}


	.material-symbols-outlined {
		margin-top: -1em;
		margin-bottom: -1em;
		position: relative;
		top: 0.25em;
	}

	#blackfon-portalcautivo-logo {
		height: 2.5em;
	}

	&.navbar-light {
		.navbar-toggler {
			border: none;
		}
	}

	#mainNavModal {
		.list-like {
			padding: 1em;
			text-align: center;
			color: $dark;
			&:hover {
				cursor: pointer;
				background-color: $dimmed-highlited;
				color: $primary;
				background-color: hsla(175, 43%, 67%, 20%);
			}
		}
	}
}
</style>