// IMPORTS
const _ = require('lodash');
const axios = require('axios');

// DEFINITIONS
const baseAPI = 'ui/loadup';

interface State {
    // banners: any[];
    // contenidos: any[];
    // ligas: any[];
    msisdn: any[];
    usuariodn: object;
}
const state: State = {
    // banners: [],
    // contenidos: [],
    // ligas: [],
    msisdn: [],
    usuariodn: {}
};

const getters = {
    uiGetMsisdnCfg: (state: State) => {
        return state.msisdn;
    },
    uiGetUsuariodn: (state: State) => {
        return state.usuariodn;
    },
};

const mutations = {
    uiSetMsisdn: (state: State, payload: any) => {
        state.msisdn = payload;
    },
    uiSetUsuariodn: (state: State, payload: any) => {
        state.usuariodn = payload;
    }
};

const actions = {
    uiLoadUp: async ({ commit }: any, args: any) => {
        try {
            const msisdn = (args.msisdn) ? args.msisdn : null,
                iccid = (args.iccid) ? args.iccid : null,
                url = (baseAPI + "/" + msisdn + '/' + iccid),
                response = await axios.get(url);
            // commit('uiSetBannersCfg', response.data.banners)
            commit('uiSetMsisdn', response.data.msisdn)
            commit('uiSetUsuariodn', response.data.usuariodn)
        } catch (e) {
            console.error(e);
        }
    },
};

// EXPORTS
export default {
    state,
    getters,
    mutations,
    actions,
};