// AXIOS SETUP
const axios = require('axios');
const utils = require('./utils.js')

import _ from 'lodash'
import { store } from "./store/index";
import { notify } from "./utils";

const emptyImg = require('@/assets/Exports/empty-placeholder.svg');

const devMode: boolean = false;

const serverURI = (devMode) ?  'http://localhost:8001/blackfon-api/index.php?r=' : 'https://blackfon.online/blackfon-api/index.php?r=';

axios.defaults.baseURL = serverURI + 'api/';
axios.defaults.timeout = 60000;

axios.interceptors.request.use((config: any) => {

	// Do something before request is sent
	config.headers['Content-Type'] = 'application/json';

	if (!!store.getters.accountGetToken)
		config.headers['AUTH-TOKEN'] = store.getters.accountGetToken;

	else if (typeof config.headers['AUTH-TOKEN'] != 'undefined')
		delete config.headers['AUTH-TOKEN'];

	return config;

}, (error: any) => {
	// Do something with request error
	return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response: any) => {
	let returnable = {
		status: false,
		class: null,
		msg: null,
		data: []
	}
	try {
		if(!_.isObjectLike(response))
			throw 'Respuesta inválida o error de conexión';
		// Do something with response data
		returnable = response.data;
		utils.mixedMSG(returnable.status, returnable.msg, returnable.class);
	} catch(e){
		notify(e)
	}
	return returnable;
}, function (error: any) {
	// Do something with response error
	return Promise.reject(error);
});

const makeImage = (img: [string, number, null], cfg: [string, undefined]) => {
	if(img){
		let imgURI = serverURI + 'img/render/' + img;
		if(cfg){
			imgURI += '/' + cfg + '/img.jpg';
		}
		return imgURI;
	} else {
		return emptyImg;
	}
}

export { serverURI, makeImage};
