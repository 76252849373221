const axios = require('axios');

import _ from 'lodash'
import Db from './loader.js';
import router from '@/router'
import { confirmFirst, notify, swalMSG } from '@/utils'
import { store } from "@/store/index";

const sessionTokenKeyname = 'sessionToken',
    userKeyname = 'userObj';

async function requireLogin() {
    let sessionToken = await getSessionToken()
    if (!sessionToken)
        router.replace({ name: 'AccountLanding' })
}

async function storeUserObj(userObj) {
    try {
        await Db.configs.where('key').equalsIgnoreCase(userKeyname).delete()
        let r = await Db.configs.add({
            key: userKeyname,
            value: JSON.stringify(userObj)
        })
        let lectura = await getUserObj()
        return (JSON.stringify(lectura) == JSON.stringify(userObj))
    } catch (e) {
        console.error(e)
    }
}

async function storeSessionToken(sessionToken) {
    try {
        await Db.configs.where('key').equalsIgnoreCase(sessionTokenKeyname).delete()
        let r = await Db.configs.add({
            key: sessionTokenKeyname,
            value: sessionToken
        })
        let lectura = await getSessionToken()
        return (lectura == sessionToken)
    } catch (e) {
        console.error(e)
    }
}


async function getUserObj() {
    try {
        let userObj = await Db.configs.where('key').equalsIgnoreCase(userKeyname).first();
        if (_.isObjectLike(userObj))
            return JSON.parse(userObj['value'])
        else
            throw 'Lectura no exitosa'
    } catch (e) {
        console.warn(e)
    }
}


async function getSessionToken() {
    try {
        let sessionToken = await Db.configs.where('key').equalsIgnoreCase(sessionTokenKeyname).first();
        if (_.isObjectLike(sessionToken))
            return sessionToken['value']
        else
            throw 'Lectura no exitosa'
    } catch (e) {
        console.warn(e)
    }
}

async function updateState() {
    try {
        let user = await getUserObj();
        store.commit('accountSetUsuario', user)
        return user;
    } catch (e) {
        notify(e)
    }
}

async function tryToLogin({ email, password }) {
    try {
        const response = await axios.post('account/trylogin', { email, password })
        if (response.status) {
            await Promise.all([
                storeUserObj(response.data),
                storeSessionToken(response.data.token)
            ])
            await updateState()
            return true;
        }
    } catch (e) {
        notify(e)
    }
}

async function tryToLogout() {
    try {
        const text = '¿Seguro que deas cerrar sesión?',
            title = 'Deberás volver a ingresar con tu correo y contraseña.';
        if (!await confirmFirst({ text, title })) return false;
        await Promise.all([
            Db.configs.where('key').equalsIgnoreCase(userKeyname).delete(),
            Db.configs.where('key').equalsIgnoreCase(sessionTokenKeyname).delete(),
        ])
        store.commit('accountClean')
        swalMSG('Sesión cerrada con éxito')
        router.replace({name: 'ManualMSISDN'})
        return true;
    } catch (e) {
        notify(e)
    }
    return false;
}

export {
    sessionTokenKeyname,
    userKeyname,
    requireLogin,
    storeSessionToken,
    getSessionToken,
    updateState,
    tryToLogin,
    tryToLogout
}