<template>
	<div class="modal-vue" :id="id" v-if="value">
		<div class="modal-vue-frame animated fadeInDown delay-1s">
			<div
				class="modal-vue-header"
				v-if="!!header && header && header != 'false'"
			>
				<div class="dt-row">
					<div class="dt-col">
						<slot name="header" />
					</div>
					<div class="dt-col dt-col-tight close" @click="closeModal">
						<i class="fa fa-times"></i>
					</div>
				</div>
			</div>
			<div class="modal-vue-body">
				<slot name="default" />
			</div>
			<div class="modal-vue-footer" v-if="!hideFooter">
				<slot name="footer">
					<div class="block">
						<button @click="closeModal">Cerrar</button>
					</div>
				</slot>
			</div>
		</div>
	</div>
</template>


<script>

export default {
	props: {
		value: {
			type: [String, Boolean, Number],
		},
		hideFooter: {
			type: Boolean,
			default: true,
		},
		header: {
			type: [String, Boolean, Number],
			default: true,
		},
		id: {
			type: String,
		},
	},
	methods: {
		lockOverscroll() {
            document.body.style.overflow = 'hidden'; // disable scrolling on the body
		},
		unlockOverscroll() {
            document.body.style.overflow = ''; // disable scrolling on the body
		},
		closeModal() {
			this.$emit("input", false);
		},
	},
	watch: {
		value() {
			if (this.value) {
				this.lockOverscroll();
			} else {
				this.unlockOverscroll();
			}
		},
	},
};
</script>

<style lang="scss">
@import '/src/scss/app.scss';
@import '@fortawesome/fontawesome-free/css/all.css';

:root {
	--primary: $primary;
	--primary-light: lighten($primary, 10%);
	--primary-dark: darken($primary, 15%);
	--primary-transparent: fade(darken($primary, 15%), 15%);

	--secondary: $secondary;
	--secondary-light: lighten($secondary, 15%);
	--secondary-dark: darken($secondary, 15%);
	// --secondary-light: fade(darken($secondary, 15%), 15%);

	--error: $error;
	--error-light: lighten($error, 15%);
	--error-dark: darken($error, 15%);
	--error-transparent: fade(darken($error, 15%), 15%);

	--dark: $dark;
	--error-light: lighten($dark, 15%);
	--error-dark: darken($dark, 15%);
	--error-transparent: fade(darken($dark, 15%), 15%);

	--danger: $error;
	--danger-light: lighten($error, 15%);
	--danger-dark: darken($error, 14%);

	--padding: 1em;
	--small-padding: 0.5em;
	--subtil: $dimmed;
	--dimmed: $dimmed;
	--dimmed-light: lighten($dimmed, 10%);
	--dimmed-dark: darken($dimmed, 10%);
	--white: #ffffff;
	--lightgray: hsla(0, 0%, 94%, 0.475);
	--min-height-calc: calc(100vh - 6.4em);
	--border-style: solid 0.08rem var(--subtil);
	--border-radious: 0.5rem;

	--ui-padding: 0.5em;
	--ui-border-special: solid 1px var(--primary);

	--black: $dark;
	--black-dimmed: fade($dark, 50%);
	--primary-dimmed: rgb(139, 139, 139);
	--dark: var(--black);
	--iphone-xr-fix: calc(env(safe-area-inset-bottom) / 3);

	/* UI Helpers */
	--ui-border-size: 0.15em;
	--ui-border: var(--ui-border-size) solid fade($dark, 20%);
	--ui-gap: 0.75em;
	--ui-padding: 0.75em 1.25em 0.9em 1.25em;
	--ui-padding-small: 0.5em;
	--ui-font-weight: normal;
	--ui-font-style: italic;
	--ui-radius: 0.5em;
	--ui-font-size: 0.9rem;
	--ui-border-radius: 3em;
	--ui-transition: ease all 300ms;
	--ui-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.1);
	--ui-box-shadow: var(--ui-shadow);
	--ui-avatar-length: 7.5rem;
}

.text {
	&-white {
		color: var(--white);
	}
	&-primary {
		color: var(--primary);
	}
	&-secondary {
		color: var(--secondary);
	}
	&-danger {
		color: var(--danger);
	}
	&-black {
		color: var(--black);
	}
	&-dimmed {
		color: var(--dimmed);
	}
	&-small {
		font-size: 0.8em;
	}
}

.modal-vue {
	$fixedMargin: 6em;
	z-index: 1001;
	overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.342); 
    backdrop-filter: blur(10px);    
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;


	&-frame {
		z-index: 1;
		margin-top: $fixedMargin;
		margin-bottom: $fixedMargin;
		width: 80vw;
		max-width: 720px;
		margin-left: auto;
		margin-right: auto;
		background-color: var(--white);
		border-radius: var(--ui-radius);
		box-shadow: var(--ui-shadow);
	}
	.dt-row {
		display: flex;
		flex-flow: dt-row;
		grid: 1em;

		&.dt-row-end {
			align-items: end;
			justify-content: end;
		}

		&.dt-row-centered-v {
			align-items: center;
			justify-content: center;
		}

		& > .dt-col {
			flex: 1;

			&.dt-col-tight {
				flex: 0 0 auto;
			}
		}
	}
	&-header {
		text-align: center;
		border-bottom: var(--ui-border);
		color: #000000;
		// font-family: $playRegular;
		font-size: 1.2rem;
		font-weight: 400;
		& > DIV {
			padding: 1.2rem;
		}
		.close {
			cursor: pointer;
		}
	}
	&-body {
		padding: 1em 0;
	}
	&.no-body {
		.modal-vue-body {
			padding: 0;
		}
	}
	&-footer {
		text-align: right;
		border-top: var(--ui-border);
		padding: calc(var(--ui-font-size) / 1.618);
		padding: 1em;
		border-top: 1px solid $dimmed-highlited;

		button {
			position: relative;
			z-index: 1;

			// font-weight: 600;
			// padding: 0.8em 1.5em;

			padding: 0.25em 1.0em;
			border-radius: 0.3em;
			border: none;
			overflow: clip;
			color: #ffffff;
			font-size: 1.1rem;

			text-align: center;
			background: $accent;

			&:before {
				transition: all 250ms ease-in-out;
				z-index: -1;
				content: " ";
				position: absolute;
				top: 0;
				left: 0;
				width: 0%;
				height: 100%;
				// background: $gradPrimary;
			}
			&:hover {
				background-color: $accent;
			}
			&:active {
				background-color: $accent;
			}
			&.dimmed {
				background: #d7d6d6;
				color: #454545;
				&:before {
					background: linear-gradient(
						50deg,
						#eeeeee 0%,
						#d7d6d6 100%
					);
				}
				&:hover {
					background-color: #d7d6d6;
				}
			}
			&:hover:before {
				width: 100%;
				max-width: 10em;
			}
		}
		
		.button-holder {
			// margin: 0 !important;
			// padding: 0 !important;
		}
	}
}

.swal-button {
	background: $accent;
	color: $black;
	&:not([disabled]):hover,
	&:active {
		background: $accent-dimmed;
	}
	&--cancel {
		background: $dimmed;
		color: $white;
		&:not([disabled]):hover,
		&:active {
			background: $dimmed-highlited;
		}
	}
}
</style>