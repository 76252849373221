// IMPORTS
import _ from 'lodash'
const axios = require('axios');

// DEFINITIONS
const baseAPI = 'account/loadup';

interface State {
    usuario: {
        token: null,
        nombre: '',
        email: '',
        imagenes_id_avatar: null
    };
}

const emptyUsuario: State = {
    usuario: {
        token: null,
        nombre: '',
        email: '',
        imagenes_id_avatar: null
    }
}

const state: State = {
    usuario: _.clone(emptyUsuario.usuario),
};

const getters = {
    isLogged: (state: State) => {
        return (_.isObjectLike(state.usuario) && state.usuario.token) ? true : false;
    },
    accountGetUsuario: (state: State) => {
        return _.isObjectLike(state.usuario) ? state.usuario : _.clone(emptyUsuario.usuario);
    },
    accountGetToken: (state: State) => {
        let token = null;
        try {
            if (!_.isObjectLike(state.usuario))
                throw 'Not object like!';
            token = state.usuario.token;
        } catch (e) {
            console.log('No state', e)
        }
        return token;
    },
};

const mutations = {
    accountSetUsuario: (state: State, payload: any) => {
        state.usuario = payload;
    },
    accountClean: (state: State) => {
        state.usuario = _.clone(emptyUsuario.usuario)
    }
};

const actions = {

};

// EXPORTS
export default {
    state,
    getters,
    mutations,
    actions,
};